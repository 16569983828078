import React from 'react';
import './Footer.css';



const Footer = () => {
  return(
    <div className="Footer">
      <span className='copy'>Copyright &copy; Dashboard 2023 Moidi Team6.</span>
    </div>
  )
}

export default Footer;